<template>
  <div class="b-inline-block">
    <button
      v-if="!loggedUser"
      type="button"
      class="btn btn-outline-light"
      data-test-id="btn-login-header"
      @click="login()"
    >
      Minha conta
    </button>

    <b-dropdown
      data-test-id="menu-header"
      v-if="loggedUser"
      :text="loggedUser"
      variant="outline-light"
    >
      <b-dropdown-item data-test-id="menu-header-minha-conta" to="/cliente">Minha conta</b-dropdown-item>
      <b-dropdown-item to="/cliente/minha-indicacoes">Saldo indicação</b-dropdown-item>
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-item-button @click.prevent="logout()"
        >Sair</b-dropdown-item-button
      >
    </b-dropdown>
  </div>
</template>

<script>
import EventBus from "../eventBus";
import auth from "../auth";

export default {
  data() {
    return {
      loggedUser: undefined,
    };
  },
  created() {
    this.updateLoggedUser();

    EventBus.$on("update-login", () => {
      this.updateLoggedUser();
    });
  },
  methods: {
    updateLoggedUser() {
      var userInfo = auth.getUserInfo();
      if (userInfo?.id) {
        var userName = userInfo.nome || "Usuário";
        this.loggedUser = userName.split(" ")[0];
      } else {
        this.loggedUser = undefined;
      }
    },
    logout() {
      auth.logout();

      this.acessarRota("/login");
    },
    login() {
      this.$router.replace("/cliente");
    },
    acessarRota(rota) {
      if (this.$router.currentRoute.path != rota) {
        this.$router.push(rota);
      }
    },
  },
};
</script>

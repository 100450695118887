<template>
  <div class=" sub-header ">
    <b-navbar toggleable="lg" type="dark" class=" bg-dark">
      <div class="container">
        <b-navbar-brand href="https://seguro.bikeregistrada.com.br">
          <img
            src="/img/logo.svg"
            width="200"
            height="45"
            alt="Logo Bike Registrada"
          />
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <li class="nav-item">
              <a
                href="https://seguro.bikeregistrada.com.br/#cobertura"
                class="nav-link"
                >O seguro</a
              >
            </li>
            <li class="nav-item">
              <a
                href="https://seguro.bikeregistrada.com.br/#como-funciona"
                class="nav-link"
                >Como funciona</a
              >
            </li>
            <li class="nav-item">
              <a
                href="https://seguro.bikeregistrada.com.br/#planos"
                class="nav-link"
                >Planos</a
              >
            </li>
            <li class="nav-item mr-4">
              <a
                class="nav-link"
                href="https://seguro.bikeregistrada.com.br/#faq"
                >Dúvidas</a
              >
            </li>

            <li class="nav-item">
              <LoggedUserButton></LoggedUserButton>
            </li>
          </b-navbar-nav>
        </b-collapse>
      </div>
    </b-navbar>

    <div class=" container my-5">
      <div class="text-center">
        <img src="/img/campanhaPedala.png" style="width:70%;" />
        <div style="font-size:27px; padding-top:20px">
          <b>Indique</b> os seus amigos e <b>ganhe</b> descontos no
          <b>seguro</b> da sua bicicleta.
        </div>
        <div class="upik-gradient-thin mb-5"></div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-6">
          Participe agora do nosso programa de indicação <b>Pedala Junto</b>
          <p>
            <br />
              <b>COMO FUNCIONA?</b>
            <br />
                <ul>
                  <li> Preencha suas informações no formulário ao lado e receba seu <b>link personalizado</b>;</li>
                  <li> Compartilhe o link com pessoas interessadas no Seguro Bike Registrada. Utilize sua rede de contatos, grupos de WhatsApp, etc.</li>
                  <li> A cada pessoa que adquirir o seguro por meio do seu link, você poderá escolher entre <strong>receber dinheiro</strong> em cashback ou <strong> descontos </strong>no seu próprio seguro de bicicleta;</li>
                  <li> O desconto será aplicado nos próximos 12 meses, com base no número de indicações bem-sucedidas que você fizer: <strong> Desconto de 5% para 2 indicações, 10% para 4 indicações e 15% para 6 indicações</strong></li>
                  <li> Caso você opte pelo cashback, poderá receber <strong>  {{ formatacaoMoeda(config.SALDO_RESGATE_MEMBER_GET_MEMBER) }} por cada indicação</strong> bem-sucedida que realizar!</li>
                  <li> Registre-se agora e comece a compartilhar seu link personalizado;</li>
                  <li> O saldo acumulado pode ser resgatado posteriormente em seu painel.</li>
                </ul>
          </p>
          <!-- </div> -->
        </div>
        <div class="col-sm-12 col-md-6">
          <b-card class="embaixador " v-show="!linkIndicacao">
            <h4 class="mb-3">Preencha agora e receba o seu link:</h4>
            <div v-if="Auth.getUserInfo().nome">
              <span
                ><b>Olá, {{ Auth.getUserInfo().nome }}.</b><br />
                Como você já é um cliente, basta clicar no botão abaixo.</span
              >

              <button
                class="btn bg-success text-white btn-lg btn-block mt-3"
                @click.prevent="gerarLinkClienteLogado()"
                :disabled="loading"
              >
                <span v-if="!loading">Gerar meu link</span>
                <span v-if="loading">
                  <!-- <img src="img/loading-double-rings.svg" width="32" /> -->
                  <small>Gerando...</small>
                </span>
              </button>
            </div>
            <ValidationObserver v-slot="{ handleSubmit }">
              <form
                class="mt-2"
                @submit.prevent="handleSubmit(gerarLink)"
                v-if="!Auth.getUserInfo().nome"
              >
                <div class=" mb-3 form-group">
                  <ValidationProvider
                    name="nome"
                    rules="required"
                    v-slot="{ errors, classes }"
                  >
                    <label for="firstName">Nome completo</label>
                    <input
                      type="text"
                      class="form-control"
                      name="nomeCompleto"
                      :class="classes"
                      v-model="nomeCompleto"
                    />
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>

                <div class="my-3 form-group">
                  <ValidationProvider
                    name="e-mail"
                    rules="required"
                    v-slot="{ errors, classes }"
                  >
                    <label for="lastName">E-mail</label>
                    <input
                      type="email"
                      class="form-control"
                      :class="classes"
                      v-model="email"
                    />
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>

                <div class="my-3 form-group">
                  <label>Telefone</label>
                  <ValidationProvider
                    name="telefone"
                    rules="required|min:14"
                    v-slot="{ errors, classes }"
                  >
                    <input
                      type="tel"
                      class="form-control"
                      :class="classes"
                      v-model="telefone"
                      v-mask="['(##) ####-####', '(##) #####-####']"
                      autocomplete="nope"
                      data-test-id="input-telefone"
                    />
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>

                <div class="my-3 form-group">
                  <ValidationProvider
                    name="cpf"
                    rules="required"
                    v-slot="{ errors, classes }"
                  >
                    <label for="firstName">CPF</label>
                    <input
                      type="tel"
                      class="form-control"
                      placeholder="000.000.000-00"
                      v-mask="'###.###.###-##'"
                      v-model="cpf"
                      :class="classes"
                    />
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <ValidationProvider
                    name="senha"
                    rules="required"
                    v-slot="{ errors, classes }"
                  >
                    <label for="firstName">Senha</label>
                    <input
                      type="password"
                      class="form-control"
                      v-model="senha"
                      :class="classes"
                    />
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="text-right">
                  <button
                    type="submit"
                    class="btn btn-success btn-block"
                    :disabled="loading"
                  >
                    <span v-if="!loading">Gerar meu link</span>
                    <span v-if="loading">
                      <!-- <img src="img/loading-double-rings.svg" width="32" /> -->
                      <small>Cadastrando...</small>
                    </span>
                  </button>
                </div>
              </form>
            </ValidationObserver>
          </b-card>
          <div v-show="linkIndicacao">
            <b-card class="mb-2 mt-5">
              <h5>👇 Aqui está seu link de indicação!</h5>
              <div>
                <b-form-input
                  :value="linkIndicacao"
                  id="myInput"
                  type="text"
                  readonly
                  style="font-size:14px;"
                ></b-form-input>
              </div>
              <div>
                <b-button
                  class="mt-4 mb-2"
                  block
                  @click.prevent="copiarLink()"
                  variant="primary"
                  >Copiar link</b-button
                >
              </div>
              <b-button
                block
                :href="
                  `https://api.whatsapp.com/send?text=Olá, fiz o seguro da minha bike e agora pedalo protegido(a). Recomendo você fazer o seu também.                                                                                                  
                                                        
                                                        Clique no link abaixo para proteger sua bike agora mesmo 

                                                        ${linkIndicacao} 
                                                         
                                                        Abraços, ${nomeCompleto}`
                "
                target="_blank"
                variant="success"
                ><b-img src="/img/whatsapp.svg" height="22px"></b-img>
                Compartilhar link</b-button
              >
            </b-card>
          </div>
        </div>
      </div>
    </div>

    <Rodape :smoothScroll="true" :menuHeight="menuHeight"></Rodape>
  </div>
</template>

<script>
import captureError from "../helpers/captureError";
import axios from "axios";
import config from "../config";
import Auth from "../auth";
import Swal from "sweetalert2";
import LoggedUserButton from "../components/LoggedUserButton";
import Rodape from "../components/Rodape";
import formatacaoMoeda from "../helpers/formatacaoMoeda";

export default {
  components: {
    LoggedUserButton,
    Rodape,
  },
  data() {
    return {
      Auth,
      config,
      formatacaoMoeda,
      nomeCompleto: "",
      email: "",
      cpf: "",
      senha: "",
      telefone: "",
      linkIndicacao: undefined,
      loading: false,
      menuHeight: 71,
    };
  },
  beforeRouteEnter(to, from, next) {
    if (config.FEATURE_FLAGS.MEMBER_GET_MEMBER) {
      next();
    } else {
      next("/login");
    }
  },
  methods: {
    async gerarLink() {
      if (!this.nomeCompleto || !this.senha || !this.email || !this.cpf) {
        return;
      }
      this.loading = true;
      try {
        var data = {
          nome: this.nomeCompleto,
          email: this.email,
          cpf: this.cpf,
          senha: this.senha,
          telefone: this.telefone,
        };

        var response = await axios.post(
          `${config.API_URLV2}/mgmTransacaoIndicacao`,
          data
        );
        this.linkIndicacao = response.data;
        this.loading = false;
        return;
      } catch (error) {
        captureError(error);
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Não foi possível gerar seu link. Tente novamente mais tarde ou contate o suporte.",
        });
      }
    },
    async gerarLinkClienteLogado() {
      if (!this.Auth.getUserInfo().id) {
        return;
      }
      this.loading = true;

      try {
        var idCliente = this.Auth.getUserInfo().id;

        var response = await axios.post(
          `${config.API_URLV2}/mgmTransacaoIndicacao/usuarioLogado/${idCliente}`,
          {}
        );
        this.linkIndicacao = response.data;
        this.loading = false;
        return;
      } catch (error) {
        captureError(error);
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Não foi possível buscar seu link. Tente novamente mais tarde ou contate o suporte.",
        });
      }
    },
    copiarLink() {
      var copyText = document.getElementById("myInput");
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand("copy");

      Swal.fire({
        position: "center",
        icon: "success",
        title: "O link de indicação foi copiado!",
        text: `${copyText.value}`,
        showConfirmButton: true,
        timer: 20000,
      });
    },
    onClickLogout() {
      Auth.logout();
      this.$router.replace("/login");
    },
  },
};
</script>

<style lang="less" scoped>
.embaixador {
  background-color: #23ad213b;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}
.navbar {
  transition: all 0.3s ease;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.61803) 30%,
    rgba(255, 255, 255, 0) 100%
  );
}

.nav-link {
  color: rgba(255, 255, 255, 0.854) !important;
  min-height: 48px;
}
.nav-link:hover {
  text-decoration: underline;
}

.sub-header {
  margin-bottom: 70px;
}
</style>

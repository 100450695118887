<template>
  <footer class="bg-dark py-5 text-center text-md-left">
    <div class="container">
      <div class="row">
        <div class="col-md mb-5">
          <nav class="nav flex-column">
         
            <a  class="nav-link" href="https://seguro.bikeregistrada.com.br/">Home</a>

           
            <a class="nav-link" href="https://seguro.bikeregistrada.com.br/#cobertura"
              >O seguro</a
            >

            <a  class="nav-link" href="https://seguro.bikeregistrada.com.br/#como-funciona"
              >Como funciona</a
            >
            <a  class="nav-link" href="https://seguro.bikeregistrada.com.br/#planos">Planos</a>

            <a class="nav-link" href="https://seguro.bikeregistrada.com.br/#faq">Dúvidas</a>
            <a
              class="nav-link"
              href="http://blog.bikeregistrada.com.br/"
              target="_blank"
              >Blog</a
            >
            <a
              class="nav-link"
              href="https://bikeregistrada.com.br/selo"
              target="_blank"
              >Selo de segurança Bike Registrada</a
            >
            <!-- <a class="nav-link disabled" href="#">Acessar minha conta</a> -->
          </nav>
        </div>
        <div class="col-md mb-5">
          <div>
            <i>Seu seguro é uma parceria:</i>
            <div class="mt-2">
              <a href="https://bikeregistrada.com.br/" target="_blank">
                <img
                  width="150"
                  src="/img/logo.svg"
                  alt="Logo Bike Registrada"
                />
              </a>
              <h1 class="d-sm-inline-block ml-3 align-middle">+</h1>
              <a href="http://www.essor.com.br" target="_blank">
                <img width="150" src="/img/logo_essor.webp" alt="Logo Essor" />
              </a>
            </div>
          </div>

          <div class="mt-5">
            <h5>Fale com a gente!</h5>

            <a
              class="d-block text-light mobile-target"
              target="_blank"
              href="tel:+556135224521"
              >+55 (61) 3522-4521</a
            >

            <a
              class="d-block text-light"
              href="mailto:seguro@bikeregistrada.com.br?Subject=Seguro%20Bike%20Registrada"
              target="_top"
              >seguro@bikeregistrada.com.br</a
            >

            <small>
              <div class="mt-3">
                SCS QUADRA 04 BL A LOTE 219/237, S/N,
                <div class="d-inline-block">1º Andar - Ed. Vera Cruz</div>
              </div>
              <div>Asa Sul, Brasília-DF | CEP: 70.304-913</div>
              <div>CNPJ: 19.654.238/0001-69</div>
              <div>SUSEP: 15414.900790/2019-59</div>
            </small>
          </div>
        </div>
      </div>
      <div class="row pb-5">
        <div class="col-md mb-5 redes-sociais">
          <h5>Acesse e curta nossas redes sociais</h5>

          <a
            class="text-light mr-3"
            href="https://instagram.com/bikeregistrada"
          >
            <i class="fab fa-instagram"></i>
          </a>
          <a class="text-light mr-3" href="https://fb.com/bikeregistrada">
            <i class="fab fa-facebook-square"></i>
          </a>
          <a class="text-light mr-3" href="https://youtube.com/bikeregistrada">
            <i class="fab fa-youtube"></i>
          </a>
          <a class="text-light mr-3" href="https://twitter.com/bikeregistrada">
            <i class="fab fa-twitter-square"></i>
          </a>
        </div>
        <div class="col-md">
          <h6>Site seguro</h6>
          <div class="d-inline-block bg-white rounded">
            <img
              class="mt-2"
              src="/img/ssl-site-seguro.webp"
              width="120"
              alt="Site seguro"
            />
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import config from "../config";
import formatacaoTelefone from "../helpers/formatacaoTelefone";

export default {
  props: ["smoothScroll", "menuHeight"],
  data() {
    return {
      config,
      formatacaoTelefone,
    };
  },
};
</script>

<style scoped>
.redes-sociais a {
  font-size: 32px;
}

.nav-link {
  color: rgba(255, 255, 255, 0.854) !important;
  min-height: 48px;
}
.nav-link:hover {
  text-decoration: underline;
}

.mobile-target {
  min-height: 48px;
}
</style>
